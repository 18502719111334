body{
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    background: #fff !important;
  }
  
  .container{
    display:flex;
      align-items:center;
      justify-content:center;
      height:100vh;
      width:100%;
  }
  .card {
    box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%), 0 0 1.25rem rgb(30 34 40 / 4%);
    border: 0;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(164,174,198,.2);
    border-radius: 0.4rem;
}


.card-body {
    flex: 1 1 auto;
    padding: 2rem 2rem;
}
.marginLogout{
  cursor: pointer;
  margin-right: 17px;
  margin-top: 26px;
}
.text-center {
    text-align: center!important;
}
  .header-form{
    margin-bottom:15px;
  }
  button{
    margin-top:40px;
    margin-bottom:10px;
  }
  .message{
    display:flex;
    justify-content:space-between
  }
  .social{
    display:flex;
    justify-content:space-around;
    padding:30px;
  }
  
  .social i{
    font-size:35px;
  }
  
  .social i:hover{
    color:red;
    cursor:pointer;
  }
  
  
  