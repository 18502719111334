html {
  font-family: sans-serif;
  font-size: 14px;
}

table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

label {
  display: none;
  cursor: pointer;
  font-size: 20px;
}

input:checked+label {
  display: inline-block;
}

.checks {
  cursor: pointer;
  font-size: 13px;
  border-radius: 50%;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.table>:not(caption)>*>* {
  padding: 0.3rem 0.4rem !important;
}

.expandChildTable:before {
  content: "+";
  display: block;
  cursor: pointer;
}

.expandChildTable.selected:before {
  content: "-";
}

.custom-card1 {
  background-color: #def8fc !important;
  border-radius: 10px !important;
}

.card {
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.pt20 {
  padding-top: 20px;
}

.icon-size {
  height: 19px;
}

.round {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
  height: 19px;
  width: 19px;
}


.roundBlue {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 10px;
  border-bottom: 7px solid #78b13f;
  border-right: 7px solid #78b13f;
}

.circle {
  position: relative;
  background: #3c67df;
  margin: auto;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  position: absolute;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(40deg);
  height: 9px;
  width: 5px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  margin-top: -2px;
}

.checkmarkRed {
  position: relative;
  background: #dc3c6c;
  border-radius: 50%;
  margin: auto;
  width: 20px;
  height: 20px;
}

.checkmarkRed:before, .checkmarkRed:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 9px;
  width: 2px;
  background-color: white;
}

.checkmarkRed:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkmarkRed:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}


/* .circleRed {
  position: relative;
  background: #dc3c6c;
  border-radius: 50%;
  margin: auto;
  width: 19px;
  height: 19px;
}

.checkmarkRed {
  position: absolute;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
  left: 33%;
  top: 44%;
  height: 9px;
  width: 4px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
} */

.asktable th:first-child,
.asktable td:first-child {
  border-top: hidden !important;
  border-left: hidden !important;
  border-bottom: hidden !important;
}

.asks {
  background-color: white !important;
  border-left: hidden !important;
  border-top: hidden !important;
  border-bottom: hidden !important;
  width: 25px;
}

.displayNone {
  display: none;
}
.bouncer {
  display: flex;
  position: absolute;
  top: 50vh !important;
  left: 50% !important;
}

.bouncerText {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

    
.minus:hover  .displayNone {
  display: block;
  cursor: pointer;
}

/* .noteText{
  position: absolute;
  width: 30%;
  bottom: 75px;
} */


/* @media (max-width: 992px) {
  .noteText{
    position: absolute;
    width: 99%;
    left: 11px;
    bottom: -296px;
}
} */
/* input[value=off]+label {
  color: grey;
}

input[value=yes]+label {
  color: darkgreen;
}

input[value=no]+label {
  color: maroon;
} */